import { storeAsyncData } from '~global/helpers';
const levels = [
  {
    title: 'All',
    level_name: 'Shop For All',
    source: require('~assets/img/header/shop/icon-all-v2.svg'),
    tagIndex: -1,
    tags: [],
  },
  {
    title: 'Girls',
    level_name: 'Girls',
    source: require('~assets/img/header/shop/icon-girls-v2.svg'),
    tagIndex: -1,
    tags: [],
  },
  {
    title: 'Boys',
    level_name: 'Boys',
    source: require('~assets/img/header/shop/icon-boys-v2.svg'),
    tagIndex: -1,
    tags: [],
  },
  {
    title: 'Mom',
    level_name: 'Motherhood Stage',
    source: require('~assets/img/header/shop/icon-mom-v2.svg'),
    tagIndex: -1,
    tags: [],
  },
];

export function checkLevels(key, storage) {
  let updateKey = false;
  const result = levels.map((item, index) => {
    if (item[key] !== storage[index][key]) {
      storage[index][key] = item[key];
      updateKey = true;
    }
    return storage[index];
  });
  updateKey && storeAsyncData('@storage_levels', result);
  return result;
}
export default levels;
