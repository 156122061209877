// eslint-disable-next-line no-unused-vars
import { StyleSheet } from 'react-native';
import { isNative } from './screens';

const data = {
  deliusunicaseRegular: {
    fontFamily: 'satoshi-medium',
  },
  deliusunicaseBold: {
    fontFamily: 'satoshi-black',
  },
  poppinsBold: {
    fontFamily: 'Poppins',
    ...(!isNative && { fontWeight: 700 }),
  },
  poppinsSemiBold: {
    fontFamily: 'Poppins',
    ...(!isNative && { fontWeight: 600 }),
  },
  poppinsMedium: {
    fontFamily: 'Poppins',
    ...(!isNative && { fontWeight: 500 }),
  },
  satoshiBlack: {
    fontFamily: 'satoshi-black',
  },
  satoshiBold: {
    fontFamily: 'satoshi-bold',
  },
  satoshiBoldItalic: {
    fontFamily: 'satoshi-bold-italic',
  },
  satoshiMedium: {
    fontFamily: 'satoshi-medium',
  },
  satoshiMediumItalic: {
    fontFamily: 'satoshi-medium-italic',
  },
  satoshiRegular: {
    fontFamily: 'satoshi-regular',
  },
};

const styles = StyleSheet.create({
  ...data,
});

export { styles, data };
