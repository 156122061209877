const logEvent = () => {
  return true;
};

const logPurchase = () => {
  return true;
};

const logPurchaseGA = () => {
  return true;
};

const logBannerCLick = () => {
  return true;
};

const logEgiftProduct = () => {
  return true;
};

const logCurrentScreen = () => {
  return true;
};

export {
  logEvent,
  logPurchaseGA,
  logPurchase,
  logEgiftProduct,
  logCurrentScreen,
  logBannerCLick,
};
