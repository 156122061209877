/**
 * StoreList
 * @constructor
 * @param {...object} args store object
 */

const StoreList = class extends Array {
  constructor(...args) {
    super(...args);
    Object.defineProperty(this, 'props', {
      value: ['name', 'address'],
      writable: true,
    });
  }

  filterStore(keyword) {
    if (keyword.trim().length > 0) {
      return this.filter((store) =>
        keyword
          .toLowerCase()
          .split(' ')
          .every((keyword) =>
            this.props.some((prop) =>
              store[prop].toLowerCase().includes(keyword),
            ),
          ),
      );
    }
    return this;
  }
};

export default StoreList;
