export default {
  // base colors
  black: 'black',
  white: 'white',
  red: 'red',
  green: 'green',
  blue: 'blue',
  // color palletes
  purple0: '#8b7fbf',
  purple1: '#d7cdf5',
  purple2: '#f3f0fc',
  purple3: '#EAE4FA',
  purple4: '#A775C8',
  purple5: '#9F77C0',
  purple6: '#DFCFE9',
  purple7: '#25182E',
  purple8: '#FAF5FF',
  purple9: '#7A7280',
  purple10: '#F3E9FF',
  purple11: '#EEDDFF',
  purple12: '#F5EEF9',
  purple13: '#FBF3FF',
  purple14: '#A774C8',
  purple15: '#A675C7',
  purple16: '#EBE3F6',
  purple17: '#E9D9FF',
  purple18: '#BD98D5',
  purple19: '#E1DAF8',
  purple20: '#AD9EDA',
  purple21: '#FBFAFF',
  purple22: '#F9F5FF',
  purple23: '#C5A0CA',
  purple24: '#C9ABDD',
  purple25: '#DECEE4',
  purple26: '#C8AADC',
  purple27: '#DFCFED',
  purple28: '#F4EEF8',
  purple29: '#FDFCFE',
  purple30: '#A776C8',
  purple31: '#A179C5',
  purple32: '#654E91',
  purple33: '#78707E',
  purple34: '#EADEF2',
  purple35: '#EBD2FF',
  purple36: '#E1B9FC',
  purple37: '#61468E',
  purple38: '#D4B1EF',
  purple39: '#BD98D6',
  purple40: '#DDCAEA',
  purple41: '#C9AADD',
  purple42: '#FAF8FC',
  purple43: '#E1CDEE',
  purple44: '#F9F5FB',
  grey0: '#E5E5E5',
  grey1: '#8c8582',
  grey2: '#6F6F6F',
  grey3: '#f8f8f8',
  grey4: '#f9f9f9',
  grey5: '#efefef',
  grey6: '#d8d8d8',
  grey7: '#CFCEDD',
  grey8: '#F4F3F4',
  grey9: '#EDEAEA',
  grey10: '#F8F6FD',
  grey11: '#F0F0F0',
  grey12: '#8A8A8A',
  grey13: '#D8D8D8',
  grey14: '#F3F3F3',
  grey15: '#F6F6F7',
  grey16: '#f5f5f5',
  grey17: '#89828E',
  grey18: '#ACA7AF',
  grey19: '#FBFAFB',
  grey20: '#CBC8CD',
  grey21: '#E4E3E6',
  grey22: '#F0EFF0',
  grey23: '#5C5263',
  red0: '#ef6354',
  red1: '#da2a52',
  red2: '#F05B68',
  red3: '#F04B5C',
  red4: '#F05868',
  red5: '#FFDAE6',
  blue0: '#6A83CB',
  blue1: '#1878F3',
  yellow0: '#FCF6BB',
  yellow1: '#E6C358',
  yellow2: '#FFE680',
  pink0: '#F185A7',
  black0: '#404040',
  black1: '#262626',
  black2: '#222',
  black3: '#2A1630',
  black4: '#0d0d0d',
  pastel0: '#fff6f6',
  pastel1: '#FFF0F0',
  pastel2: '#FFF6F4',
  pastel3: '#FF9A8F',
  pastel4: '#FCE9DC',
  pastel5: '#FFBC8F',
  pastel6: '#FFEBDE',
  pastel7: '#FFEFE4',
  pastel8: '#FFECD8',
  pastel9: '#FDF4ED',
  pastel10: '#FFEFE5',
  pastel11: '#FFFAF6',
  pastel12: '#FDC8C2',
  pastel13: '#F8CAC3',
  pastel14: '#FFF5F4',
  pastel15: '#FFFAF7',
  pastel16: '#FDE7DB',
  orange1: '#FFBE91',
  orange2: '#FFF0E5',
  orange3: '#FC7D7A',
  orange4: '#F8C9C2',
  orange5: '#F0495B',
  orange6: '#F26C7A',
  orange7: '#FCD7DB',
  orange8: '#F1AA78',
  orange9: '#FFE6D5',
  orange10: '#FCEBE8',
  orange11: '#FFDDE3',
  overlay: '#00000060',
  transparent: 'transparent',
};
